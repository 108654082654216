import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/IP_Symcon/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IP Symcon Smarthome",
  "path": "/Software/Other_Platforms/IP_Symcon/",
  "dateChanged": "2019-04-25",
  "author": "Mike Polinowski",
  "excerpt": "The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.",
  "social": "/images/Search/P_SearchThumb_Symcon.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IPSymcon_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "IP Symcon"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IP Symcon Smarthome' dateChanged='2019-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.' image='/images/Search/P_SearchThumb_Symcon.png' twitter='/images/Search/P_SearchThumb_Symcon.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/IP_Symcon/' locationFR='/fr/Software/Other_Platforms/IP_Symcon/' crumbLabel="IP Symcon" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "ip-symcon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ip-symcon",
        "aria-label": "ip symcon permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Symcon`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#features"
        }}>{`Features`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#send-commands-to-instar"
            }}>{`Send commands to INSTAR:`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#status-feedback"
            }}>{`Status feedback:`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#requirements"
        }}>{`Requirements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#loading-the-module"
            }}>{`Loading the module`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configuration-in-ip-symcon"
            }}>{`Configuration in IP-Symcon`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-web-interface"
        }}>{`The Web Interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#events--scripts"
        }}>{`Events & Scripts`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.symcon.de/"
      }}>{`IP-Symcon software`}</a>{` is the complete solution in the field of home automation. Combine products of all renowned manufacturers, realize your wishes, and allow your home to think for itself. A multitude of assistants and tools allow a comfortable configuration with a focus on clarity and security. Needless to say, privacy is a must with the optional Cloud connection.`}</p>
    <p>{`The IP Symcon Software can be used on the proprietary Hardware called `}<a parentName="p" {...{
        "href": "https://www.symcon.de/shop/#symbox"
      }}>{`SymBox Neo`}</a>{`. The SymBox Neo is the complete solution with the integrated SymOS specially developed for IP-Symcon.`}</p>
    <p>{`Alternatively, start by downloading and installing the IP-Symcon Software version 5.x on `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/windows/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/macos/"
      }}>{`macOS`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/linux/"
      }}>{`Linux (Debian)`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/raspberry-pi/"
      }}>{`Raspberry Pi`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/qnap/"
      }}>{`QNAP`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/synology/"
      }}>{`Synology`}</a>{`.`}</p>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/IP_Symcon/Installation_on_a_RaspberryPi/"
      }}>{`Installation on a RaspberryPi`}</a></p>
    <p>{`We will be using the `}<a parentName="p" {...{
        "href": "https://github.com/Wolbolar/IPSymconInstar/blob/master/docs/README.md"
      }}>{`INSTAR Module`}</a>{` to connect the IP Symcon installation with an INSTAR IP camera.`}</p>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <p>{`The INSTAR Module can send commands to an INSTAR HD or Full HD camera and alarm events on the `}<a parentName="p" {...{
        "href": "https://www.instar.de/"
      }}>{`INSTAR`}</a>{` camera can be used to trigger events in the IP-Symcon software (version 5 or higher).`}</p>
    <h3 {...{
      "id": "send-commands-to-instar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#send-commands-to-instar",
        "aria-label": "send commands to instar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Send commands to INSTAR:`}</h3>
    <ul>
      <li parentName="ul">{`Control (up, down, left, right, stop)`}</li>
      <li parentName="ul">{`Set position and start`}</li>
      <li parentName="ul">{`Camera settings (contrast, brightness, color)`}</li>
    </ul>
    <h3 {...{
      "id": "status-feedback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-feedback",
        "aria-label": "status feedback permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status feedback:`}</h3>
    <ul>
      <li parentName="ul">{`Display a live image`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.instar.de/"
        }}>{`INSTAR`}</a>{` camera can send notifications to the IP-Symcon software`}</li>
      <li parentName="ul">{`Email notification for events`}</li>
    </ul>
    <h2 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.symcon.de/service/dokumentation/installation/"
        }}>{`IP-Symcon 5.x`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instar.de/"
        }}>{`INSTAR IP camera`}</a></li>
    </ul>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <h3 {...{
      "id": "loading-the-module",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#loading-the-module",
        "aria-label": "loading the module permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loading the module`}</h3>
    <p>{`Open the IP Symcon web console inside your web browser with `}<em parentName="p">{`http://\\<IP-Symcon IP`}{`>`}{`:3777/console/`}</em>{`. Then click on the module store icon (IP-Symcon > 5.1) in the upper right corner:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df3ed88aab2adff996fac44140e50204/ae694/INSTAR_IP_Symcon_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACGElEQVQoz1WP3UtTcRjH998IEWmKIOFNU7R8S+fLlqmF9EKzLtpcNxGZoFTLAomoC+vCi9IMuhGtm1ChpBeKEiMk5vGcTdncmefsuLPtnPP7xBkY9MD3+cL3gQ/P11Pb2kd95yBe3zmONfdS3einuqGHyrrOfzrqyuv7Lyuv76Kp9gT1jd1UNfRwvH2Atv4hPHWDI7QEx2m8MEq5L8SRjlDJy31hKnzhkld2DVPVHSnlFaU8xKGuCOe9/bT7w9QM3KT58hitwXE8h9uuUnYySE0gQt/1BwSuRfGH7hEIRwmEovQO3+fU0BhNF29zJjJRytzb6VAUX3gC//AE3rM3KGsKcqjlCp47T2cYmZxmbnGFtQ2JnYyKpufIaAYHI28nWf3+iz3dIKlqyDu7QjdyIIQAxPKnn9yanBYuy5NRd1F3UxhZHctxeDezzsv5N2h2kUTsGzvbEoVCgbSqIsW3kZQEm3LCdZFIpklrBlldF5l0CpflURSFTUliX9f5shWje/QxHQ/v8uTDe37Pr7L2+QeWY7G+EWN2YYnZhWVezC+JmYUVns0tOs9fvxV/JIV4PC5kWcGTSCSQFYW9TAbTKnLp1RQ9U4+Q1DRrXz8ib8WwbBvTNBHCcRtysBzbcmyrKIxsFlmWhcsqAd0vk6kUwrYpFors53LgOORtGyNnous6mqZhWVapfj6fdyXMfB5j30RVVeEyXOBfMgvjVy8LNjEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df3ed88aab2adff996fac44140e50204/e4706/INSTAR_IP_Symcon_01.avif 230w", "/en/static/df3ed88aab2adff996fac44140e50204/d1af7/INSTAR_IP_Symcon_01.avif 460w", "/en/static/df3ed88aab2adff996fac44140e50204/4c41f/INSTAR_IP_Symcon_01.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df3ed88aab2adff996fac44140e50204/a0b58/INSTAR_IP_Symcon_01.webp 230w", "/en/static/df3ed88aab2adff996fac44140e50204/bc10c/INSTAR_IP_Symcon_01.webp 460w", "/en/static/df3ed88aab2adff996fac44140e50204/10237/INSTAR_IP_Symcon_01.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df3ed88aab2adff996fac44140e50204/81c8e/INSTAR_IP_Symcon_01.png 230w", "/en/static/df3ed88aab2adff996fac44140e50204/08a84/INSTAR_IP_Symcon_01.png 460w", "/en/static/df3ed88aab2adff996fac44140e50204/ae694/INSTAR_IP_Symcon_01.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df3ed88aab2adff996fac44140e50204/ae694/INSTAR_IP_Symcon_01.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the search field type INSTAR, press Enter and click on the INSTAR module:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dececc2a976386495d9091ed2463de07/ae694/INSTAR_IP_Symcon_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACPElEQVQ4y42Sy28SURTGZ12x0cQIrQ+ChVYttTWmrUY3urA7ExMHbAID/Btu7Ma/wYUbNbapLWK0MSY+IrBp0Oi6GwZY0Bl51BTmzusO85k7pc0E0PYkX87N3HN/5zs5wwXCcwhO38D5y7PwBmfgCzFddeV+sTr/5Cz8k3POu7Er13Fhat55w50em8bw2YsYPjMBz+g4jo9OwDMy7pxZHvIGMeTrkTcIz0jI0TFfCCfOXYIvOOM04gLheTx99hzLaxm8XE3jxfJrrKxn8Go1jZW1DDLvP+DNu8Faf7uBjY+f8OjxE5wKTOGkPwzu3n0esrQNstOE0qij3doFpSY0VQU1TRwlfnwv4Nbtu7h28w64VCqFsiii+fMX5M1NGLoOVVWhaZqT24oCZaAI6s0dGCZFLpfD4uJDJJMJcMlkEuVyGdSyQBQFarsN3YFp0HUdpmHAcMk0Tec7a6gQ4jj8/OUrHvA8YrFYF1ipwKIUKgADQLNFUKnWoKj6wBEtyzqYgMW3bBaRSATxeLwLLJVgAdAKBXSWlvCnWIRUr0OWJMiyjGq1CkmSHLVarT5gPp9HNBrdcygIgl0uFm0G1NNp215YwO7WFrYbDfyWZdRqNRBCHABTp9PpA2bdDgVBgMgcUmqzaw2AfchWKaV9QJ7n94CJRMIuiqLNOquE2Bohjov/xWEjd0qlks2KVFW1WSHbotGzXbf2t0y6W2a/jXtkW+wBss6s+F9i9wzqBjKHB0C3Q1Z4FLkd7o/MgH8BWGZ9TuGxTscAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dececc2a976386495d9091ed2463de07/e4706/INSTAR_IP_Symcon_02.avif 230w", "/en/static/dececc2a976386495d9091ed2463de07/d1af7/INSTAR_IP_Symcon_02.avif 460w", "/en/static/dececc2a976386495d9091ed2463de07/4c41f/INSTAR_IP_Symcon_02.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dececc2a976386495d9091ed2463de07/a0b58/INSTAR_IP_Symcon_02.webp 230w", "/en/static/dececc2a976386495d9091ed2463de07/bc10c/INSTAR_IP_Symcon_02.webp 460w", "/en/static/dececc2a976386495d9091ed2463de07/10237/INSTAR_IP_Symcon_02.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dececc2a976386495d9091ed2463de07/81c8e/INSTAR_IP_Symcon_02.png 230w", "/en/static/dececc2a976386495d9091ed2463de07/08a84/INSTAR_IP_Symcon_02.png 460w", "/en/static/dececc2a976386495d9091ed2463de07/ae694/INSTAR_IP_Symcon_02.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dececc2a976386495d9091ed2463de07/ae694/INSTAR_IP_Symcon_02.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<em parentName="p">{`Install`}</em>{` to install the Module:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "849px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0562caaa88151ae40c568672e3d52fb0/f96df/INSTAR_IP_Symcon_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACPklEQVQ4y5WR224SURiF5xHUqAEPKKTUUmkptVRNvOqFJm1UGMgMDR0Oj8G1vesdxBBrfAdO4wOYaExUGNS0tKjRNKa1cp5dYfbsPb8yFOkBNP3y303WrLXXYkzjLsvELbP9psE6deaq/ax5Qr/JgWcadxmtTpNtZsRx22RzMQar85zFYbQ6zfbZS2M3LttmLl6bvjDqHHKdT8ZR53mLwzAyxZy+YjeNTT95+uzVu/ev30ov3+Slj+sbpc/FzU/FzdKRWytufPn6LSM+n5y9c8p0nbm38HB+4b4oii/ypR/laqXWkBHSKMFY0TQNDkMpBYDi+hrH8XfnHzCRSNjn86XTaQBQFEWjVFVVrKoYY/UYiqIAQC6X4/18OBxiBEFgWVYURUIIQggAjvkddc7nJdbrEwSBCQaDXq9XFEVN0+r1OvyTrrhQKPj9/r44m80SQmu1k4s9Hk8mk1GU1s7O92az3mw2ZFlGCO0dAOnIskwpzeVyHMfti91uj+4MW1v17W0ZoT2qQ3RUVSU9MMZdZ57n+87ZbBYAyuVWrdavedhU/dihUMjtdvem+kWIgnt0xQd/8Vd8yDmVSikK3t392Wq1/luYJEn9wro7A0C1Wm232ydrm2XZ7psbjUa3kgHo4SkhsLJSSCY7XS8t7cdOJpOEkEqlgjGmAyGEAqgIwdycFI9zkYgQCOhTeTwZ3ZkQMiywBqBSjWgayOiDJPkXFzuxBUHgOC4ajSYSiVgsFh9OTOfx6uqj5eVAIPBH/Bv1rhJSESVV5wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0562caaa88151ae40c568672e3d52fb0/e4706/INSTAR_IP_Symcon_03.avif 230w", "/en/static/0562caaa88151ae40c568672e3d52fb0/d1af7/INSTAR_IP_Symcon_03.avif 460w", "/en/static/0562caaa88151ae40c568672e3d52fb0/1450a/INSTAR_IP_Symcon_03.avif 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0562caaa88151ae40c568672e3d52fb0/a0b58/INSTAR_IP_Symcon_03.webp 230w", "/en/static/0562caaa88151ae40c568672e3d52fb0/bc10c/INSTAR_IP_Symcon_03.webp 460w", "/en/static/0562caaa88151ae40c568672e3d52fb0/51e4d/INSTAR_IP_Symcon_03.webp 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0562caaa88151ae40c568672e3d52fb0/81c8e/INSTAR_IP_Symcon_03.png 230w", "/en/static/0562caaa88151ae40c568672e3d52fb0/08a84/INSTAR_IP_Symcon_03.png 460w", "/en/static/0562caaa88151ae40c568672e3d52fb0/f96df/INSTAR_IP_Symcon_03.png 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0562caaa88151ae40c568672e3d52fb0/f96df/INSTAR_IP_Symcon_03.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "847px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d3401d7c3faeedbf192e329885ef93a/b2cef/INSTAR_IP_Symcon_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwUlEQVQoz42P32vTUBTH8weID87RoRRbm6J1dsvYhmN2L8JExQffSlMr0tK9q136H2qXjk2HoluHL8Vh7k1MmqS5P8410eVqtWyIX76ch8P5nO85SvbWar58Jze/Oqdql67Nz+Ruz+TKsl7OT3k2X86WVuaKWvbmcmFhLVtaUTKqNltYvL6wXlzaULVKcamS1g1Vu1tYXD/jX82MqmVUTbl4tbRc2Xz9pmf293bM3d5Of//dQc/sH7z/cDg4/nQ0+NsfD48Gx5/39t8+eFK9cOWGsvnwcePZc9u2LctybHschlEUBYHPGfsuxMQirQDAOaeUdLa3791/pDQaT7e22gghz/Nc16WMUUo5Y1EUjX8rTDcSQoIw9P3A8zzDMHS9ptRqtXa7jTEWQtBUo2Bsuz7nkCRJHMdJkgCAaZrD4VAme6PRi5evqtWqouu6hAGAEEIpddzRV/wtIpRzLjsAIP/inDPGgiDodrvnwIwxjNHJyRcpy7Iwxo7jiPRhOTAFt1othNAkmTEmhwBOL5eK45hSKvu+73c6nVO4ruvNaVj+eVaTpf9KBgBxnibJf+B6vd5sNhFCQgjyH5JnG4bxE/4BptYFUZY9TEoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d3401d7c3faeedbf192e329885ef93a/e4706/INSTAR_IP_Symcon_04.avif 230w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/d1af7/INSTAR_IP_Symcon_04.avif 460w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/97edd/INSTAR_IP_Symcon_04.avif 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d3401d7c3faeedbf192e329885ef93a/a0b58/INSTAR_IP_Symcon_04.webp 230w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/bc10c/INSTAR_IP_Symcon_04.webp 460w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/8a8c4/INSTAR_IP_Symcon_04.webp 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d3401d7c3faeedbf192e329885ef93a/81c8e/INSTAR_IP_Symcon_04.png 230w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/08a84/INSTAR_IP_Symcon_04.png 460w", "/en/static/7d3401d7c3faeedbf192e329885ef93a/b2cef/INSTAR_IP_Symcon_04.png 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d3401d7c3faeedbf192e329885ef93a/b2cef/INSTAR_IP_Symcon_04.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "configuration-in-ip-symcon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuration-in-ip-symcon",
        "aria-label": "configuration in ip symcon permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration in IP-Symcon`}</h3>
    <p>{`Before the actual instance is created, a category must be created at a desired position in the object tree. In this category, one image is stored in case of a motion detection event or manual when manually prompted. To create a category open the Object Tree and click on the `}<strong parentName="p">{`+`}</strong>{` icon:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "848px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f61fc1634cb81a25cc10cde9084587c8/d52e5/INSTAR_IP_Symcon_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/klEQVQoz23J3UtTYQDH8fPXRDcrFiW9XBh40xsbTlPzwmgX1V7OxtbLwBD2agu7MtJtIDSQ0QJrQYikUIZQODOHZCptbTs7Ozt69jznPDs7O89ezhPqRQV9+F79ftSVO/5e+nGfM9xza6xr4MHZoYf/9mc5d8NjsIZ6zGPXLIFB98Sl2z7q4sij88OeroH7epNLZ3CcMDqP0hmcepPrVJ9LZzwYdUaHzug4c/2e3uQ63e++MOzpHhmljl229tLjdDBm8U7b/BF7IGrzRyzeKToUG3I/7XeGHaGY1RehgzF7MGrxTd/1TtkC0UH3xPGrdso7Obvy9Xtm+9eeJMm4IwDU7nQ0jRBCssXy529bQJIhkjkBcvuwgZvkUI7h/M8TFKwKck1uaZ21T7mPCynQqHewCLl1RVFUtYEQEiVJFCWhCgCECKFWu61ppNnEsCpQ+UJRw80vpdxNfyQUeUanEkpZSM0kuUoFq42dbCE5v5ycX46nluKppRdvFmfm3sdeLWxu53iep4oMozVbq/nsySej3ZNh++sEFusf4nMISRirAoA/88xutsBUhKMyWzvrmz8EIHIcR5VKpVqtRgh5u7EWXHyHmKLycjazsQIVtX5AJv+jqirLshTLslUAZFluY0wIUdJp1WyWVtO73B7P8+Dwqv1FktC+ACCE5XL5N9C2gIKp5DEqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f61fc1634cb81a25cc10cde9084587c8/e4706/INSTAR_IP_Symcon_05.avif 230w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/d1af7/INSTAR_IP_Symcon_05.avif 460w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/d6773/INSTAR_IP_Symcon_05.avif 848w"],
              "sizes": "(max-width: 848px) 100vw, 848px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f61fc1634cb81a25cc10cde9084587c8/a0b58/INSTAR_IP_Symcon_05.webp 230w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/bc10c/INSTAR_IP_Symcon_05.webp 460w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/27a2c/INSTAR_IP_Symcon_05.webp 848w"],
              "sizes": "(max-width: 848px) 100vw, 848px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f61fc1634cb81a25cc10cde9084587c8/81c8e/INSTAR_IP_Symcon_05.png 230w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/08a84/INSTAR_IP_Symcon_05.png 460w", "/en/static/f61fc1634cb81a25cc10cde9084587c8/d52e5/INSTAR_IP_Symcon_05.png 848w"],
              "sizes": "(max-width: 848px) 100vw, 848px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f61fc1634cb81a25cc10cde9084587c8/d52e5/INSTAR_IP_Symcon_05.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/ae694/INSTAR_IP_Symcon_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACmUlEQVQ4y32R2U8TURTG5y/CqKhE3/TFaAxEAVGfeHALiQtlM4orLhGqxuCDMSKKS+KrMcbEaLAStilaaIGyaIe2s7YVSjt31n7m3tJRBJ3ky5xz5pzf/e4Zbt/JDuw9cQ0VdS3YWtuEbQebmSrq/i/au/voFeyqb8ee41dR2XAd2w+3gjvU5EdtYyd21rdjx5E21rhx/1mUVZ5CWeXpNdpQ9Vubqxux6UAjyqt92FLjQ3mNDxwfnsPgt2kMhaLgw7NMoxOzGBmfYRoORb2Y6ktwEoHgJAbGphDgIyymtQ+DIVQ13AD3tp/Hm08j6B+NgI/MYzBUhA+FpjE8PoNgZI7FpXxkYgaBYASf+TD6+TDLB75OsXxsYgrcubvP0HrnKS5196HN/xhnbj5Cc9cT+G73oNXfi4vdL+Hr7EFTZw+rX37wCi3+XhY3d/Wi4+FrXLj/HOfv9WHuewycYZowLRt6bhGakoSiajAMA5ZlwTQtGIbJ3uZKTkr5iggx2Hwm8xOCsACuUCjAdR0QQqDICgQhBk1V4bouHMcB/V4oFOP1ZbOeTCYDQRDA5XI5mKYJwzIhpTRomgZd11kzrWezWSYa27a9RvQm9HAPKIoi9HweOYNgVhYhSxIs22ZA6jqRSCAej2N5eZnV1oOuAqZSKVCXOaJD0FRoisJOpcN0l/RAWZY91/9ymE6ni0BFUWAQAsO2Ma9IyKRSsFf2UwLSHnrlNUC6Blq3baRLDlVVBdF1BvyhKpBE0RsuAZPJJLvF30AHgI3iQ8FJUfzToYWYqkCVZW+YAiVJAj10aWlptTvXRf7ZC+Trj2HhYwDzSprNcnQ/RCcgloVoMoHs4qLnhP4U+kNoT2lfnhwH+XfvoXfcQmwoiGhcYsBfrKfBXz8O8UIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbbe07cca6d36aaa23be7406cd423a7b/e4706/INSTAR_IP_Symcon_06.avif 230w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/d1af7/INSTAR_IP_Symcon_06.avif 460w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/4c41f/INSTAR_IP_Symcon_06.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbbe07cca6d36aaa23be7406cd423a7b/a0b58/INSTAR_IP_Symcon_06.webp 230w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/bc10c/INSTAR_IP_Symcon_06.webp 460w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/10237/INSTAR_IP_Symcon_06.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbbe07cca6d36aaa23be7406cd423a7b/81c8e/INSTAR_IP_Symcon_06.png 230w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/08a84/INSTAR_IP_Symcon_06.png 460w", "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/ae694/INSTAR_IP_Symcon_06.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbbe07cca6d36aaa23be7406cd423a7b/ae694/INSTAR_IP_Symcon_06.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<strong parentName="p">{`Category`}</strong>{` and name it, e.g. with `}<strong parentName="p">{`OfficeCam`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51baf1db13a017769e94d52e8aad68bc/ae694/INSTAR_IP_Symcon_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYklEQVQY032PzUpbURSF7xtESkFCE4NYm94E/5OK2jQdpElb7KQGBwYNZiAUWgqKOLYP1amDPoAPoBUKRZPecs65P+f+EJJP7qGDCuKGD/baa7NgWU+Ky0zZFbJPF5jIl8jkbCbyttkfIpO3eVQoMzkzz+PpOQqlKrnnK1jZ2SWeLayx3tjkxeu3VOvvWK61WHrZZHHjPt78o2l+Vmoto3N2xQRb5dU6ra1tLi5/opMxruvieR5KKYSUSINCSYWrlPFT0rsQEqkUNzd9jo5PWH3VwOq1t/luLyI/fQH1Ax1IHKkZjcf8P9e+y7nTRwcxno7ueMPhkM9fD2m9/4DV+9jmrFBEtncQ15f8cQb0Bw5CCINyXcajEcr3+TXo4zh/Ua6H1pogCIiiiDiO2dk/YL3ewNrtdtnrdvl9dYXwNFJIfN83tdLaaf0kSYjCkMDzCAKfJI5NUErqhWHIt9NTOp0Ot9/wUCr1smDaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51baf1db13a017769e94d52e8aad68bc/e4706/INSTAR_IP_Symcon_07.avif 230w", "/en/static/51baf1db13a017769e94d52e8aad68bc/d1af7/INSTAR_IP_Symcon_07.avif 460w", "/en/static/51baf1db13a017769e94d52e8aad68bc/4c41f/INSTAR_IP_Symcon_07.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51baf1db13a017769e94d52e8aad68bc/a0b58/INSTAR_IP_Symcon_07.webp 230w", "/en/static/51baf1db13a017769e94d52e8aad68bc/bc10c/INSTAR_IP_Symcon_07.webp 460w", "/en/static/51baf1db13a017769e94d52e8aad68bc/10237/INSTAR_IP_Symcon_07.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51baf1db13a017769e94d52e8aad68bc/81c8e/INSTAR_IP_Symcon_07.png 230w", "/en/static/51baf1db13a017769e94d52e8aad68bc/08a84/INSTAR_IP_Symcon_07.png 460w", "/en/static/51baf1db13a017769e94d52e8aad68bc/ae694/INSTAR_IP_Symcon_07.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51baf1db13a017769e94d52e8aad68bc/ae694/INSTAR_IP_Symcon_07.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3885320e3f8101dba6a8abf3bca079e9/ae694/INSTAR_IP_Symcon_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3UlEQVQoz2OQ0TRW0DFT1DGXVjcWVdITU9YXVzHAhRS0zaTVjeW1zZR0QeoZJFQNJVUNRRR1RRR1RRV1RBV1BGQ1eaXUeCSxIAEZTT5pdUE5LTFlfTFlfQYZTRM9c7ucoootW7euWrdh2eoNi5avWbhszdJV68FoHYyxfsmKtQuXrV6xdlNL1wQ9c3tlPQsGD5+AkLDw9evX/f+379On1y/ffnr26s3Hz1//YwN///79////zZs3U5KTg8MiGMIiItUNzGfOXfj//58fXz7/+vkTgr59+44Mvn379usXSOb///8nTp3RMrH28A1gCAmLMLS0W7hoye/zF1/s3P374aN/////A9uAy+Yz586bWDt5+gYwJCYmhoeHb9yw4f+vX+9evfr7C2T2/38g4sfPX39RTYFwr127Fh8fHxsTA9IcHBy8bt26////f/j48e8/EPj7D6T7y7fvf/5g0Xz16tW4uLjo6GiQ5pCQkLVr14I0f/jw/ft3kMU4wJ8/f/7//3/58uXY2Fio5uDg4LVr1/758+fNmzcfPnyAO/Uf2H5Mmy9evBgZGRkDd/aaNWv+////DRzEf/78wbTz79+/P3/9/v0bw+bQ0FCInyGuwhXOX799/w1WcOXKFYhmAHDQ8CsURYd9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3885320e3f8101dba6a8abf3bca079e9/e4706/INSTAR_IP_Symcon_08.avif 230w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/d1af7/INSTAR_IP_Symcon_08.avif 460w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/4c41f/INSTAR_IP_Symcon_08.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3885320e3f8101dba6a8abf3bca079e9/a0b58/INSTAR_IP_Symcon_08.webp 230w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/bc10c/INSTAR_IP_Symcon_08.webp 460w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/10237/INSTAR_IP_Symcon_08.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3885320e3f8101dba6a8abf3bca079e9/81c8e/INSTAR_IP_Symcon_08.png 230w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/08a84/INSTAR_IP_Symcon_08.png 460w", "/en/static/3885320e3f8101dba6a8abf3bca079e9/ae694/INSTAR_IP_Symcon_08.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3885320e3f8101dba6a8abf3bca079e9/ae694/INSTAR_IP_Symcon_08.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can add the module `}<strong parentName="p">{`Instance`}</strong>{` by again clicking on the `}<strong parentName="p">{`+`}</strong>{` icon in the Object Tree and this time select `}<strong parentName="p">{`Instance`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/ae694/INSTAR_IP_Symcon_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABdklEQVQY022PzUsbURTFZ18wFCsiJqFtwCah0UqaNCZajPGrxvGjFtpFSAtdNCC4Ke2mi/x3brsubRexaF40M86b9zIfiPmVjBsFL/y4l3vgcI4RT+dJZPJMpmaJxTOMxdPRvp80sWSWB6kXjCUyPExmmXiaY/zxc5KZl0w/y2NMzcwzM19m+c0+S+u7VNZ2KNVMSivbvLpNtU6hZrJYWeNHMk15aYNizWShZlKsbpHIFnj0JIcxV1qm8fETnc4Jnn+F1pqB1riuiyMlMsLFdSTK99F//nJ18AH56zeO5+FKl64QfP32ndLrVYy9g3dsmm/pds9AH6PUJReXmuGQO/NPOvw8FwwGIVbfvqOFYcCXwyM26jsY27v7lFc2OT09Q1on9HoC0bvAtm0sy4oSDq+vcZRLRwj6fQupNZ7nRW2CIIju983PLFbXMZrNJq1WCyEEli1xHIlWKqo8MlNKEYbhjYFSkUkYBPi+HzHSRr92u02j0eA/WuhRuyA3cBEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39ba5bfd4746abf7c19cdcd3721b4917/e4706/INSTAR_IP_Symcon_09.avif 230w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/d1af7/INSTAR_IP_Symcon_09.avif 460w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/4c41f/INSTAR_IP_Symcon_09.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/39ba5bfd4746abf7c19cdcd3721b4917/a0b58/INSTAR_IP_Symcon_09.webp 230w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/bc10c/INSTAR_IP_Symcon_09.webp 460w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/10237/INSTAR_IP_Symcon_09.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39ba5bfd4746abf7c19cdcd3721b4917/81c8e/INSTAR_IP_Symcon_09.png 230w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/08a84/INSTAR_IP_Symcon_09.png 460w", "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/ae694/INSTAR_IP_Symcon_09.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/39ba5bfd4746abf7c19cdcd3721b4917/ae694/INSTAR_IP_Symcon_09.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`INSTAR`}</strong>{` as the `}<strong parentName="p">{`Vendor`}</strong>{` for the instance, add a name and click on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OK`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/8efc2/INSTAR_IP_Symcon_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADE0lEQVQ4y22TW0/TUBzA9xkUFLwBEyU4mBMRSTTGEBNMSMCgPkBJTrvBnuR7mIDRRyMoDz5oQBMTExNAwHFJTIzGmXWXLtHoYFu7dW23db2ec7QrlzH3y/+hl/M7/0tPHU0Xuls6rjZ39Jxs66pzuuud7vqzF4/ViuOtnibXlcbzl5pdPa3u3hNtXY6GVk/DuUuN5zyn2rrOtHefbr/ceN5T19JxpMn1fxxzdh5tdtW1dB5t7qxzuh1Nrp7rfbefzb38ENj4uLaxvLaxtBpYXF1fDWytBDat+LRpXyyvrS+uBFbXtxbevb9xa+BsZ69jYHAYAPLzj+ASt50T8n+S7J8kywuSqeumYZiGjhBEEJqmiTFGCGGMJUmanHxwZ/i+Y2j4nqf35pev3xA0dU3DGGFrrVE2LdmwdtFhhby9s3P5Wl//wKBj6O69a33934M/Umw2keI4XsoIEi8V01kxnRUTSe5XIrnD8iwvprNCkuOlohJj4tf7+geGhh0kSY6P+8Lh8JvFjalnr6Zm5p/MvXn8Yn5qZv7R8wU7pp8vTM/OP5pdePj09dzbpRAdHh/3URTlAAB4vd5oNKqU5O1EQhSEbDaTSiZFQZDE6hByvKYqDMNQFEWSpJUZABAOhzHGehnDMEzT1GuhqirGmGEYsowlEwRB0zTGWJZlVVWVw1Q+kWUZIcQwjNfrtcomSXJ0dLRSrkylaZqiKJqmVWXelQEABEGEQiFFUQRBsF/vo6pqoVDQrE9oASHEGMfj8YOex8bGQqFQsViUZdlegcrg/9iXD2WmabpUKomiiCoolhSzvLpKPhgYAGBkZMTuWdO0SrmkqLCWfKhsgiCCwSBCUBJFXdftY2iXjQ5jy7FYbL/s3Z5zUiGby+l7szEhLKlaVc/2jolEwufzHQyMpumMKGdzgigI9g8kK+oOx1eNzb5lWdbv9x/I0WgkGi8mU3w+L9mV18SW0+n0rrx3tiM/fxdYLlco5BVFQQiZJqzC6qVcVCqVmpiYAABYMkVRkUhkf5g1gRCKedkwTIgQx2X8fv8/+S9aDLIFT38pPgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c89b4aeff1cefce27344ca43bdb14d7/e4706/INSTAR_IP_Symcon_10.avif 230w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/d1af7/INSTAR_IP_Symcon_10.avif 460w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/ec170/INSTAR_IP_Symcon_10.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c89b4aeff1cefce27344ca43bdb14d7/a0b58/INSTAR_IP_Symcon_10.webp 230w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/bc10c/INSTAR_IP_Symcon_10.webp 460w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/712de/INSTAR_IP_Symcon_10.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c89b4aeff1cefce27344ca43bdb14d7/81c8e/INSTAR_IP_Symcon_10.png 230w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/08a84/INSTAR_IP_Symcon_10.png 460w", "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/8efc2/INSTAR_IP_Symcon_10.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c89b4aeff1cefce27344ca43bdb14d7/8efc2/INSTAR_IP_Symcon_10.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Finally, we can add our camera. Select your camera model and type in it's IP address and web (http) port - default is port `}<strong parentName="p">{`80`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "./INSTAR_IP_Symcon_11.png",
        "alt": "IP Symcon & INSTAR Kameras"
      }}></img></p>
    <p>{`A little bit further down you can add your camera login (admin user), assign the category we created earlier for the camera snapshots and apply the changes:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "827px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/4e6ec/INSTAR_IP_Symcon_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACiElEQVQ4y5WRz08TQRTH95/xjJoWhMhBoyZqjEjaQhMOJHhSFEwrWmiFmCCBSEwg1WgIiYdiCUqMMWpiPHjwqvGgFgOU7e/tTndndks73dmZ1e7UgiAVPplsXnb3s9/33goXrox3Xp/w3Jg63Tfq9Phbum4e6x5yun3Nf2p+WrqH2ry3XAOTJ3uDF/vvef3TZy6PCu09gfaeQKt3iJsOt8/h8p3qGz3RG3K4fU0dA4cvDTZ1DPDC4fYd6Rx0uHxOj7/Z4xe8/unwwpuJJ8/HHy89ePrq/vzLybnlyOuP88sfJude3JldCM5ExsLPxsLR0EwkZNehmcjdh9G+4Kzw/tPnlFSIp6VkDsTWUyvxdCoHiEkopYwxZlnrKUnfLOOKkQNqDqgYG6Uyhvrml+9rQiqT+7aaSEsgkcmLmbyYlvIKRPom5EcrKkjnR0W1WtV0VStCpAl5GeglzKwDo6qqAACIxX5EFxeHR0YCw8MjwWAymbQsi1JqUmbave+GUiqKopDNZqPRxav91446m1vbjp89d35l5SeXG8QyxhKJhIAQsiwLgIIoivGNjXh8A2PM35C0ShZW9pKryQghwzDsvW5/Vr3eXoq7wrGKYdbvbEeWZUFVVQCAoii6rps2lFZHopR6Hq0eCnxdk0rVKXbZCCFB0zTeRr0fSqs1KpHpd5mptxneOf17c1syxhhCqNs03lM9gDEGIazKhJCSTblcptsiDEINQin9x3+qybquY4wVRUE2lUql/u292JJ525oNIeS/5k6ZEAIAKBQKCKH6T248s2VZNZmvrlgsYox5+H6oyZRSAIAsyzsG3pfMkzmmaR4s2TRNCOH+tS0ZIcQYI4TQXTTe+W/5F6hQjk4At6v9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/e4706/INSTAR_IP_Symcon_12.avif 230w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/d1af7/INSTAR_IP_Symcon_12.avif 460w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/d429d/INSTAR_IP_Symcon_12.avif 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/a0b58/INSTAR_IP_Symcon_12.webp 230w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/bc10c/INSTAR_IP_Symcon_12.webp 460w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/97d4f/INSTAR_IP_Symcon_12.webp 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/81c8e/INSTAR_IP_Symcon_12.png 230w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/08a84/INSTAR_IP_Symcon_12.png 460w", "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/4e6ec/INSTAR_IP_Symcon_12.png 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22fb08e4dd9e0a487366fdcf4e58ad19/4e6ec/INSTAR_IP_Symcon_12.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now see that your camera is accessible by the IP Symcon software:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4280be4cda1d67c666a0c159abe17397/6da96/INSTAR_IP_Symcon_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7klEQVQ4y5WTzW7aQBDH/RK9JFKr5pC2KOohadpD1EegKjEY1vs56107fBwQcnk3noEjWEbwBggOHC0KW9lL+Yhoov7113pkzW/Gmh0772++Xt18e1e6/3T38PHu4W3p/uL69pkvP9xeWF/n52XhN1efnWrddz1Ua/i5676HMKWcUI4Ja/gYU4YwcT2ECPUxrSOCirPW8EtfvjuUcZ9QzDjCFFPGBYAMCksBYM244AIESJG/lByAAxBCnV2SlBykUlprHShlbZUHWh9iGwQBpdQJQx2GGgACKWWQH1rr8DUppXJYF+KcM8byEkHw3zAACCGOsSiKns7pDKyUelYb/kocCQDOwFor+7A9tdaDwWA6nY5GoyRJJpNJmqaz2Ww4HDabTdvsABMKIGUxslwAkCRJlmWr1Wq9XhtjttutMWa5XLbbbYvsYKX1o0sZ4/vPI4SMx2NjTJZlvwvZEvP5vNVqncBaa7d2AjPG0jQ1xmw2G1PIdl4sFmdgr86LcewmxDnvdDq9Xq/b7cZx/KtQv9+P4/jMwI7naUUp5ZzbQnsJIfYwIeSwJFLa9QztXT/9Q/uLRAg5RbouFktFUdgsMqLolQ07wIFS1TpyPWR/T9dDAmT0YoET+KfrlSu1H4+1cqVWrlS5gJfhIAgwxn8AlLrFgOQ69RQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4280be4cda1d67c666a0c159abe17397/e4706/INSTAR_IP_Symcon_15.avif 230w", "/en/static/4280be4cda1d67c666a0c159abe17397/d1af7/INSTAR_IP_Symcon_15.avif 460w", "/en/static/4280be4cda1d67c666a0c159abe17397/7f308/INSTAR_IP_Symcon_15.avif 920w", "/en/static/4280be4cda1d67c666a0c159abe17397/ed796/INSTAR_IP_Symcon_15.avif 922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4280be4cda1d67c666a0c159abe17397/a0b58/INSTAR_IP_Symcon_15.webp 230w", "/en/static/4280be4cda1d67c666a0c159abe17397/bc10c/INSTAR_IP_Symcon_15.webp 460w", "/en/static/4280be4cda1d67c666a0c159abe17397/966d8/INSTAR_IP_Symcon_15.webp 920w", "/en/static/4280be4cda1d67c666a0c159abe17397/88718/INSTAR_IP_Symcon_15.webp 922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4280be4cda1d67c666a0c159abe17397/81c8e/INSTAR_IP_Symcon_15.png 230w", "/en/static/4280be4cda1d67c666a0c159abe17397/08a84/INSTAR_IP_Symcon_15.png 460w", "/en/static/4280be4cda1d67c666a0c159abe17397/c0255/INSTAR_IP_Symcon_15.png 920w", "/en/static/4280be4cda1d67c666a0c159abe17397/6da96/INSTAR_IP_Symcon_15.png 922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4280be4cda1d67c666a0c159abe17397/c0255/INSTAR_IP_Symcon_15.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you scroll down a little further, you will see an overview of the elements that you can read out and set directly via the IP Symcon interface on the camera. Select here which elements you would like to have displayed in the IP Symcon web front:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "849px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91543546297689d03cbaa1e3c8ac436d/f96df/INSTAR_IP_Symcon_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADGklEQVQ4y41TW2xMQRg+XsUz5UWEh1IiHiRE+yhFW9FSym68iHrYtmlCad8qHtzjEqJbVXpBtbuIlna3qWvasttLEOKBqNJsL3vmcmbOfc4cOXvWVorqlz+TL//M999mRsg5dKKw/EzR4XNbi49vKKrI3Fe5yVOZ5a3K9FRmeqqyvI5lepOksPz0luLqgrJTnorz2QerhSxvVX7ZyWxHeXTj3mPrC4+s3VGenuNbmevLyC1xSI4vI680PceXvs23envZqtySjLzS1dtL1+WXC/srL70c/NDxYuB+d38g1Nva5VjXq6FguK8t1NvxPPqw503Lk1ftzyLtz6LBcP+jp5H73c5adaFJGHj/CWAyEUeYyONxOCEiTGXbtqmsEqpyznXDFBGxLM65remGYTLDNDXD+PJtTEAIxqbg9/H4aGzqx4QoySomsgglKFEkURFJAFNMFICJiAmUKMDU2SIyQkgQRVE3DCxJsqJQWVY1zU6AJ5Dktp1yprZEAAQAAGMMAAAhiMfjAEA1gZTyT7hiCKEj5pxjjI0EdF1XVVXTtFnELpyyXTFCyLIse86wLGtsbCwpJoSYpsl/w+xixtjIyIgzsFQbcwdjbHR0VIAQ6rouiiJCCABAKf1vzZxz0zSdzKmeMcaSJGGMXU4ISXnclRCCEJJl2e05FotNi93k7gnG2H/zT097cnLSJcl5WNb38anZ5z99zwghCKFbs1s2lZXZMyfFlmVRShVFkWVZ1/W5XFVSnLqqGa8v6eHc/kcgRwwhZIyRBCilhNKZgdwQf33b0Cmbaaqia6quKZqmcG4lPlLSfqWe9tjOAQtCIAy/+3jrXmdzMNwUDDcFQs3BcGMg1NjW1dTaeaP9+VBxxY/Nu5pbHjcEuhrbOh0LhBraQrcfPP38bVz4+ra772pu37WC/pqCvpr81zU7B+p2R+v2RK8XRhq9sQNrSPaSYf/OyE1vtN4TrfdG63ZHagsG/XlTQ3eErz0X6ZUFxL8Y16QRfxqpXYKvLZQSXPIvQrdWgOY1pDaNXl9Kbiyj9cvp5fnkwjx6Vvhyt+gnOPii7ZOtDzwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91543546297689d03cbaa1e3c8ac436d/e4706/INSTAR_IP_Symcon_13.avif 230w", "/en/static/91543546297689d03cbaa1e3c8ac436d/d1af7/INSTAR_IP_Symcon_13.avif 460w", "/en/static/91543546297689d03cbaa1e3c8ac436d/1450a/INSTAR_IP_Symcon_13.avif 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91543546297689d03cbaa1e3c8ac436d/a0b58/INSTAR_IP_Symcon_13.webp 230w", "/en/static/91543546297689d03cbaa1e3c8ac436d/bc10c/INSTAR_IP_Symcon_13.webp 460w", "/en/static/91543546297689d03cbaa1e3c8ac436d/51e4d/INSTAR_IP_Symcon_13.webp 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91543546297689d03cbaa1e3c8ac436d/81c8e/INSTAR_IP_Symcon_13.png 230w", "/en/static/91543546297689d03cbaa1e3c8ac436d/08a84/INSTAR_IP_Symcon_13.png 460w", "/en/static/91543546297689d03cbaa1e3c8ac436d/f96df/INSTAR_IP_Symcon_13.png 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91543546297689d03cbaa1e3c8ac436d/f96df/INSTAR_IP_Symcon_13.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the Alarm section you will be able to add a `}<strong parentName="p">{`INSTAR webhook`}</strong>{` that will be used to notify the IP Symcon software through the alarm server on your INSTAR IP camera (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Alarmserver/"
      }}>{`720p series`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`1080p series`}</a>{`).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "832px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/922261884c39e076729171ff7ba86058/ef6b9/INSTAR_IP_Symcon_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACGklEQVQoz32P70sTcRzH7/9RUqfgjxKCpGdzy2m6zSdJENV5x2Xz1pOlYjItFKltDiyEgtYWaiCED6pH0YONRTGntpaW28673e3udm7ufnzvG3q6tLAP7wdvvrxffN9vpAef7MImna7pjmue5l5Xq51sc5DNva6WvuE2B3ne6TbUaic7Bjw23Hv5+shVYsrhmrnouIu0O90tfcNN3UMNNqKpZ6ihi2jsvnNpwHOh/16dFa81o7WdgzVmtMaMnrNgBy+dWL0Vr7tCmCy3kBv3fcFXq1Pziw+fLj1+sTKz8Gb2+Up49ePC8vtHz5bH58Jj/tBEMDIRjIz6Q+OB8IO5yJgv5J1ftN4cQT592fhJ5TM0m8sXUr+oze3sLstDCHVdhxCWK/IOlVcUVVHULMOJUklR1P39SqkiL739gHDJZDa5uf11fWf9Wyb2maMYXiqLxZIoHUko7h3p2POiVK4oqVQKEX9sgTyr0AzgeDVHQVUFEAJd/yMA/jKaBiCEicQakslm4/G4PxC4jaIohrtItyiKRm0N6AAclD95xhwAQDQaRdLpdDgSQQexelOj2WK1O/sLhUI19O9V4VgshkiSBCEsFosMw1AURdO0pmlG7jtdzvHy/2BBEGT5VOL4R932ZIN4uQUP09Uep2CO41iW5XlekiQAgKZphzt1RpRNo4l27xq/p54JG7VPLjQsJci+d7uvo2xZBmfBvwEI/T6sBayjfwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/922261884c39e076729171ff7ba86058/e4706/INSTAR_IP_Symcon_14.avif 230w", "/en/static/922261884c39e076729171ff7ba86058/d1af7/INSTAR_IP_Symcon_14.avif 460w", "/en/static/922261884c39e076729171ff7ba86058/fcb4d/INSTAR_IP_Symcon_14.avif 832w"],
              "sizes": "(max-width: 832px) 100vw, 832px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/922261884c39e076729171ff7ba86058/a0b58/INSTAR_IP_Symcon_14.webp 230w", "/en/static/922261884c39e076729171ff7ba86058/bc10c/INSTAR_IP_Symcon_14.webp 460w", "/en/static/922261884c39e076729171ff7ba86058/de44a/INSTAR_IP_Symcon_14.webp 832w"],
              "sizes": "(max-width: 832px) 100vw, 832px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/922261884c39e076729171ff7ba86058/81c8e/INSTAR_IP_Symcon_14.png 230w", "/en/static/922261884c39e076729171ff7ba86058/08a84/INSTAR_IP_Symcon_14.png 460w", "/en/static/922261884c39e076729171ff7ba86058/ef6b9/INSTAR_IP_Symcon_14.png 832w"],
              "sizes": "(max-width: 832px) 100vw, 832px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/922261884c39e076729171ff7ba86058/ef6b9/INSTAR_IP_Symcon_14.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure to select the `}<strong parentName="p">{`local network`}</strong>{` option and click to confirm your settings. IP-Symcon will now automatically configure your cameras alarm server to contact your IP-Symcon installation when an alarm was triggered - which will trigger an alarm snapshot displayed in the IP-Symcon we frontend.`}</p>
    <p><img parentName="p" {...{
        "src": "./INSTAR_IP_Symcon_14a.png",
        "alt": "IP Symcon & INSTAR Kameras"
      }}></img></p>
    <h2 {...{
      "id": "the-web-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-web-interface",
        "aria-label": "the web interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Web Interface`}</h2>
    <p>{`In the web frontend you can now view the live video of the camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45889c50835304a541dffc76adeab19c/ae694/INSTAR_IP_Symcon_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC4klEQVQ4y32S/UsUQRjHhw6FwguC6G/xb6n0TBGC0PBud+fO8+XupDOjzPyholBMQn9IRUWLEO8s373TO0tKhNst3/PsKvd1ZnZ2YndVpKIvX4YZZj48zzzPAwRBgBDean5c2dxT0dTla+y+0dxTGen929Wx3pt3+gLRzmgsEmtpiUajgOc5juObXi63vS3Ex/YiI3vBV9uhgZ0zto/1gzvxsd32xBHXPtBUV8NzfENjIxBgUBDgytrGQLrw4v1u/+y34XR+OJUfTueHUgcj6fzocn5w6aBvdr87sTW3ZXW1tdWUlFwpLr7u8wE/J3Acv74hpiVl+vP3mfXC/MaPlCi7TktKSpSXckeLuaPEx8OVA/a0peUaAF4ArpaVgQAP/RwvSRJjJkUKxSpjFrNlMUaYRdiJCMGMsfYH7ecBuFji9VX4ACfAAC+IokhM00DYsphrhLGsKIaBMCaudV1njHV0PgIej6eoyOez4WCAs2GMsWEYdkTLcuIQVVUVRxhjQo7h+613PQB4PZ7y8nLAC9Af4HM5ERmGLMuKolBKXVjXddM0iSOMsaZpjLHehx2lF7ylly5XOHCwLsCLomiapqqquq6fRtY07RRGCKmKwhiLt7YCAM55PHbaAgz67bQlF1ZVlRC7SJRSjDGl1HREqaloxk/ExsdfV1dV3a6tDYfDADqwJH1hjGma5gLsX9IROdRYMjnV0BCOx+P2hAUE6OcE+88IuXm6TymlhBDrRIxZuoELOpucTAiCEI1GI5HIcZ9zOZGaJkLoTFeJLMsIIXIi9zaZTEIIY7GYDXN2ZF4UJUqpoiiapp1WW5ZlXdf/B0MY4u0hkVyAEEIpdVM1TYoJRYQS064ecVqVOAuHQqEghJubm6fj4Wzsdf8X6XqXfz5TeDP9tfDk2fa9Dn1odOoPGEI4MTGxtraWyWSy2ezq6uoHR9ns6mI6u5jOLqVWsgsLK3PznzKZ/v7++vp6t2C/Ac5LBKzdhyYDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45889c50835304a541dffc76adeab19c/e4706/INSTAR_IP_Symcon_16.avif 230w", "/en/static/45889c50835304a541dffc76adeab19c/d1af7/INSTAR_IP_Symcon_16.avif 460w", "/en/static/45889c50835304a541dffc76adeab19c/4c41f/INSTAR_IP_Symcon_16.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45889c50835304a541dffc76adeab19c/a0b58/INSTAR_IP_Symcon_16.webp 230w", "/en/static/45889c50835304a541dffc76adeab19c/bc10c/INSTAR_IP_Symcon_16.webp 460w", "/en/static/45889c50835304a541dffc76adeab19c/10237/INSTAR_IP_Symcon_16.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45889c50835304a541dffc76adeab19c/81c8e/INSTAR_IP_Symcon_16.png 230w", "/en/static/45889c50835304a541dffc76adeab19c/08a84/INSTAR_IP_Symcon_16.png 460w", "/en/static/45889c50835304a541dffc76adeab19c/ae694/INSTAR_IP_Symcon_16.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45889c50835304a541dffc76adeab19c/ae694/INSTAR_IP_Symcon_16.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure your camera directly through the IP-Symcon web frontend:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "851px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8943ec5a8431edc358d721725e25b8a6/0fcea/INSTAR_IP_Symcon_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC+ElEQVQoz2XE6U9SAQAA8Fez1qYuLXmSIIgcBvIAUY6n3IiIouQ8QNHpVJYW+hzeGCBCGN6KgreSISKYF5pb/0KtubL5odUy7e9o9snV9tsP4AvlYrlKqlTzBDJOnhSW5DM4uXQ2PwPKvolCZzNyYIG8SKzUCmXFUkUxLJIDFGYegyuDZWoyBBNoXCpbiKWw06gcdDrjpmQCRKDxqFlCMoNHgnhEOh9DzgJosCoTVmXmFuGZ0lS6KBUSp7GkeKaEkCX7B54pwUFiHON6kMKvaUYAvlInUOnocCEeEqazxGgKF0XKAUk5qP+AJA5IvoZniJIoPPfMEkBkiYkQV9fS9bTf1djrqEZeaNvM2nazDjHrkAEtMqBDzNo2c5Wxv8rYX/Gst/K5mZItik0mDbpngEcZnEQs2R04Pjr7/fbj9/3Ti+iXy3dfL0/OL0/Or07Or47PfkU/X+yf/tz99CPy4dvB6QVcUBaTgB0anQUwGZykVIpl3BeIvl+JHGzsHQUP/4oeBaPH/r3D5Z3D5cjBQmh30h8eWQ2P+vchWHEnAWsf8QAp5OyHGNLw2MvtiH8j4AtszgeD8+GQNxLyhUO+YGB24403uLUwtTTfYPeVmqZU7TM4SHgvETPongbQJFZiCrGisXVq0bsRXFxbm97eHA9vTuyGJqORiWWPdd5j93oc07OvTE63vstZYnTgIfhuAsY2PAWg05kJaAI+rzxDUVuH9Pi31ld8Q95Jy/rr6d3wxE7QveqzejzOmVnX3NxL14i9z9r7mMWNiU+xDU8AIIEeD+LQWQUPmIWs/CcdfSabvd9mMTW1GuqMHZ1Wi2Wozz5o8nhdcz6n2dpnGezKZHNuxYE21xgAptHiQZy4SGNob1PVGEob28tbewpqDMX1RpFGL9caFPoWdW1zZXNrA9LdYESM3Z0kKOd2LMrqHAVQOOp9FDZXWSbWVAlK9ZKKJlmVQd1kUlQ2iUqqBZpanrIiV1PPkJSqWyzyWkSgKk9Jp8XEoayOkT99Bxr0At/jYAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8943ec5a8431edc358d721725e25b8a6/e4706/INSTAR_IP_Symcon_17.avif 230w", "/en/static/8943ec5a8431edc358d721725e25b8a6/d1af7/INSTAR_IP_Symcon_17.avif 460w", "/en/static/8943ec5a8431edc358d721725e25b8a6/7aa96/INSTAR_IP_Symcon_17.avif 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8943ec5a8431edc358d721725e25b8a6/a0b58/INSTAR_IP_Symcon_17.webp 230w", "/en/static/8943ec5a8431edc358d721725e25b8a6/bc10c/INSTAR_IP_Symcon_17.webp 460w", "/en/static/8943ec5a8431edc358d721725e25b8a6/155dd/INSTAR_IP_Symcon_17.webp 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8943ec5a8431edc358d721725e25b8a6/81c8e/INSTAR_IP_Symcon_17.png 230w", "/en/static/8943ec5a8431edc358d721725e25b8a6/08a84/INSTAR_IP_Symcon_17.png 460w", "/en/static/8943ec5a8431edc358d721725e25b8a6/0fcea/INSTAR_IP_Symcon_17.png 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8943ec5a8431edc358d721725e25b8a6/0fcea/INSTAR_IP_Symcon_17.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And in case of a motion detection (camera alarm), one snapshot is taken to give you an overview of everything that happened during the day:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "853px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e0d65a996be2507ed63b78b30073e433/66caf/INSTAR_IP_Symcon_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACTUlEQVQoz3XOW0/UQAAF4CY+CNvd7W2ml2k77bQznXZ67y6XXS6BRQMxEB/8CQYI7CqiGDXqfzcsZjUKyZeTnIeTHCnMt5N6wppd3uwk9dQiJcA5DH4DWAAs/tRlWqSUYbS4+ynF1aTcOii3D9vpUTbaM3D2NzsuYChcVmFeZ+10dvKalRuR6GDAF3c/pKjczsd79dZh2kwBToHPV8wgOzg+Oz59I9ptkjY0HxXdJMratN50wmzx6bsUiHHaTFi1Faat5sSGy1Z0l0E/2X/xanN3FouGV+OQVyStk3Jk4mT+8ZuEaBWLzg4zxSYaild0N9ZdqrmxYhPVIbQYRaKzA46izI0F8Jdjw+e0GI92juwwU22iOZG6ZLgU4gTixMTcxAnwGPDZsiYOyRAt5rdfJRXFsWirjV3VDmTgKVbwQHcI8OIV6NEHukM0h9zfvv0iebRgxUi0mzTv4qzRERlAb2j6ioUVO/jHwPSLbrI/Oym6yfXNZwmRjPASuhFABCCiQG8I3KfIukOS8uXZqTbyLj/cSpbPEtFBRNZVS9adgYEe1TeQavp9AwGHTF4cSkI+f38jEV6JesOLeF+3nyJrtmb50/0ZyyoUUMKFarsX8/txmeQty2uPJD0Fyqr5GJiIOhG1iQLHj5AfqcC9uL6RaFqyrAxYGvG8r8KeAv4nq5CmRVY0yCcIEy+kKkDnV+8kN2RRIggXmPL1gb72mJ4CFGj7hJoIa9AGtrvW195eziXN8jBhIctQQGUVPEkB60O9NzR6ChhopvTs+fnV4hcJpXgmsAqmhgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0d65a996be2507ed63b78b30073e433/e4706/INSTAR_IP_Symcon_18.avif 230w", "/en/static/e0d65a996be2507ed63b78b30073e433/d1af7/INSTAR_IP_Symcon_18.avif 460w", "/en/static/e0d65a996be2507ed63b78b30073e433/469bf/INSTAR_IP_Symcon_18.avif 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e0d65a996be2507ed63b78b30073e433/a0b58/INSTAR_IP_Symcon_18.webp 230w", "/en/static/e0d65a996be2507ed63b78b30073e433/bc10c/INSTAR_IP_Symcon_18.webp 460w", "/en/static/e0d65a996be2507ed63b78b30073e433/d8b1f/INSTAR_IP_Symcon_18.webp 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0d65a996be2507ed63b78b30073e433/81c8e/INSTAR_IP_Symcon_18.png 230w", "/en/static/e0d65a996be2507ed63b78b30073e433/08a84/INSTAR_IP_Symcon_18.png 460w", "/en/static/e0d65a996be2507ed63b78b30073e433/66caf/INSTAR_IP_Symcon_18.png 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e0d65a996be2507ed63b78b30073e433/66caf/INSTAR_IP_Symcon_18.png",
              "alt": "IP Symcon & INSTAR Kameras",
              "title": "IP Symcon & INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "events--scripts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#events--scripts",
        "aria-label": "events  scripts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Events & Scripts`}</h2>
    <p>{`IP Symcon allows you to use System Events to control you INSTAR camera. A system event can be both a time schedule or an event triggered by a different device that was added to the software.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/91f10/INSTAR_IP_Symcon_Scripts_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0ElEQVQoz5WO70sTcRzH7z8ywdJ85pMoIcrEDiHoSSVSbtM2BVHSsV1ZQSIK6jmyJMQHCtWTEYQT5nSr/dJ5brZlyOa2S495fn/ubnexHeQIHfTi9YXv5/39vuHDXH84erPbce3Bs8t3rU2srYaNrK25c6D1kb3l/tCtx87WLjtzo8t+b+BNu+Xl1c7+utvmS22Wc62/02vYyNoaOp42sf1XWBszveQOCilvSFgPxzciiWp9VYknsO0JxLxBYdW/vfYt5gvHFz55mM3Irtsb/roR+R5LRhP7ISFlGN79uVU1BneS/mhiM5rwReIhIeXf2gtE48zblS8mju8bcw2PvzNzs08cMyaO73HO9o25Bsff9zh5E8dbns8NT3zofTFn4ngzx49MLVpfzb92LTOFwgnCBGFakA7T6XRe/I0JRZhATADClaeyAKK/91OIICLyKWAghJqmlUoljImYz2ezWQiBkZTPBeiahjFmAACqqiqKIstyLpfLZDKSJBFClJqoqooQOisDAA4riKKIMTbCfykWFUrLGmWEkK7rmqZRSiVJkmW5vPAF6FVQSs/Kem0qH0r7v9DktPLx80H++Egq/F9Z3fsBRhxF1/xO8iArHv8BM79AX5j+g1oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/e4706/INSTAR_IP_Symcon_Scripts_01.avif 230w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/d1af7/INSTAR_IP_Symcon_Scripts_01.avif 460w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/7f308/INSTAR_IP_Symcon_Scripts_01.avif 920w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/6378b/INSTAR_IP_Symcon_Scripts_01.avif 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/a0b58/INSTAR_IP_Symcon_Scripts_01.webp 230w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/bc10c/INSTAR_IP_Symcon_Scripts_01.webp 460w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/966d8/INSTAR_IP_Symcon_Scripts_01.webp 920w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/9012d/INSTAR_IP_Symcon_Scripts_01.webp 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/81c8e/INSTAR_IP_Symcon_Scripts_01.png 230w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/08a84/INSTAR_IP_Symcon_Scripts_01.png 460w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/c0255/INSTAR_IP_Symcon_Scripts_01.png 920w", "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/91f10/INSTAR_IP_Symcon_Scripts_01.png 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/17e4a0f8a74e0e15e55c7e0e08dd4e8a/c0255/INSTAR_IP_Symcon_Scripts_01.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To add a scheduled event please open the object tree and click on the `}<strong parentName="p">{`+`}</strong>{` button in the bottom right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfddb5e6cf00e33c6658cee337db080a/5caea/INSTAR_IP_Symcon_Scripts_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz42STWsTQRzG83k86KV6kAqCeigePbVNNqVJ6GfxJnhUP4EgitQK6VlNNokVpEqZJdraNtnd2beZ2Xnd/cvu9F1QHwYGHubHPP95ptFqr73bej/5sjtwXXc0GrjuYHiyhq67M564o5HrjqzzaTAcf9559vzFarN1bWGxcef+EvI8gLkxsoRLMmV5QLMCzm1jCgB483bz9t0Hy8urjZuL977ufoP4Iw4Ofxz6SZZJKYQQZVHQPP/wc3rsh4ezIOdcKZXnOQC8fPX6+q3F5ZXVRrfb8TzPx8T3gySOoyjyg0paayklS7M4wjzPOed5nlNKAWB7e9txnHa73eh0OgihwlRHtdbqVFJKzrkQ4qIphACAfr/farUcxzmBwyMZJ2wWxlobACjLsgSQhCVbY4zTjPHahKIoLNxsNit4fX3dQyiJs/ncP57NkyRhjCmlAEBwER4cJ2maEXIxtoWr2N1eD02nSmtCCKslhFBKaa2FEFILraRS9RsKYR+s3++fzNxrr6HxBATllEqty7IsahmjGePf90LKci6ENW2ic3jzxgJ5tKJSHOMoSklRGK217ZMxPpz8mgVxXPmFrnUJfrz08OjJ04TzwPfDsCoJY8wYM8YoJbXm4nRaQsjV2M7Gxt7+PgBIKWXdUL3bqqqpL8rC5z33Oh0PoYDtRySVqkp19hvFH7oKd7tdz/Mymvi+jzG2VdnYUsp/wwghJXVWi1J6lvy/bkYIVTOfjmfP2b/5d/g3uhkr8X8x1ioAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfddb5e6cf00e33c6658cee337db080a/e4706/INSTAR_IP_Symcon_Scripts_02.avif 230w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/d1af7/INSTAR_IP_Symcon_Scripts_02.avif 460w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/7f308/INSTAR_IP_Symcon_Scripts_02.avif 920w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/c91b9/INSTAR_IP_Symcon_Scripts_02.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfddb5e6cf00e33c6658cee337db080a/a0b58/INSTAR_IP_Symcon_Scripts_02.webp 230w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/bc10c/INSTAR_IP_Symcon_Scripts_02.webp 460w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/966d8/INSTAR_IP_Symcon_Scripts_02.webp 920w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/ef2bd/INSTAR_IP_Symcon_Scripts_02.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfddb5e6cf00e33c6658cee337db080a/81c8e/INSTAR_IP_Symcon_Scripts_02.png 230w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/08a84/INSTAR_IP_Symcon_Scripts_02.png 460w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/c0255/INSTAR_IP_Symcon_Scripts_02.png 920w", "/en/static/dfddb5e6cf00e33c6658cee337db080a/5caea/INSTAR_IP_Symcon_Scripts_02.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfddb5e6cf00e33c6658cee337db080a/c0255/INSTAR_IP_Symcon_Scripts_02.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This time we need to choose an `}<strong parentName="p">{`Event`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e2288f48953637eefac8b7cfc51fc81/ec3e2/INSTAR_IP_Symcon_Scripts_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfElEQVQoz42RS0/CQBSF+W/u3OuiBTRu5LGBDTS4cuO/YWmCaQ1bMkWUjZEE4kIp6XRKO8WZO48+DAUT3fkt7p25yck9Z6ZSrVa73e5kMnFnM9dFrosQQm7J1HWnL/Ppy/zpaXqYIISeZ7P7h9F1o1Gv1yuGYViWJaUoxC7N8iwvjuTlKfSKyM9+rnlZPxeLZrO5F5uG0ev1aByLEG8wCcJIKSWEkFLqooD3t2j56kc74Bw4F1qrbRScnPbOzo2rq4ppmv1+P6aUxHQbkoRSzjljDAC0VgkXIU2SOCaEYIxjSlPOvdu7Tv2ienm5F1uWlcSR/kqkVFJKpZQuEUIIALlveyNSSgDIi2Llec12u7Rtmv3BTeJ9KOSQhG/wNs2yQ+o0TQFA/AIAsix7X61ax8zl5m0Y4s0GBwEhAcbY9/3StmaM8V8wxrTWq+Wy1WodxYPBQClV/IPDa6/X66O4Vqt1Op3RaPT4g+M4h2rbtvMX27bH4/FwOGyU//wNP+6z+EL7jh8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e2288f48953637eefac8b7cfc51fc81/e4706/INSTAR_IP_Symcon_Scripts_03.avif 230w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/d1af7/INSTAR_IP_Symcon_Scripts_03.avif 460w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/7f308/INSTAR_IP_Symcon_Scripts_03.avif 920w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/cc3cf/INSTAR_IP_Symcon_Scripts_03.avif 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e2288f48953637eefac8b7cfc51fc81/a0b58/INSTAR_IP_Symcon_Scripts_03.webp 230w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/bc10c/INSTAR_IP_Symcon_Scripts_03.webp 460w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/966d8/INSTAR_IP_Symcon_Scripts_03.webp 920w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/c5bb7/INSTAR_IP_Symcon_Scripts_03.webp 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e2288f48953637eefac8b7cfc51fc81/81c8e/INSTAR_IP_Symcon_Scripts_03.png 230w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/08a84/INSTAR_IP_Symcon_Scripts_03.png 460w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/c0255/INSTAR_IP_Symcon_Scripts_03.png 920w", "/en/static/5e2288f48953637eefac8b7cfc51fc81/ec3e2/INSTAR_IP_Symcon_Scripts_03.png 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e2288f48953637eefac8b7cfc51fc81/c0255/INSTAR_IP_Symcon_Scripts_03.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this example we want our camera to move between 2 different positions depending on the time of the day. For this we need to choose a cyclic event.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7185c4023072602480cffda17942a7ee/5caea/INSTAR_IP_Symcon_Scripts_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACDElEQVQoz42STW/TQBCG/Xs4gMShrYSoCiISqdQ2pYVK1tqJL4nzJzhw4AwoEaIckghx46NqxYFbvO6tl1aiUitVlLaxTSwSt3ZsJ96PQYlTk0Cj8moPu6t95p2ZHeHO/YdrIkovPfrw/p29g/cOjnb3D3b3Dw+Pjg3TahjmWcNomJb5s9kwrfb5xYtXpfmFzJqIbk7PCYvLqw/ml2bmUl+3NqFtuH7gep7ndXzf73UHCkNCIgBgjAFA+fWbe6l0enFlefWJkF7ITM2mbtye+bT5hQD4vg8AnHPGOIvFhxtCCAA8ffb81vTdqdlUZuWxoCiKqqoIIYxxNwwdx+Ec+gtgEAUS8cGhXC5LkqSqajabFXK5XKFQEEVR0zQAiKIofsoYC8MA4G+4VCohhPL5vCzLQk5RRmFCCB/a8Y7fbTsBIVFc7VXwpTPGGAA8zxuU2X/X7TH7l0/pMFySdgz301YUpVgsIoQ0TeOcB8FYqqOK/SuVyp+aExhjHEVR3O2kyNGGxXC1Wr0C1nV90KQwIcbQa2EAcF2XUsovBZQm3zURliSpXq//6/a/zhhjSlmv10uGI3Q9/2WZnZzGN9fAnU6n1WoNpxIgOL8IPm6wps0BOGOU0ond1vVtzvuzHTtMSrtWq43B/Z0sf97Y2vt2+uP4u2GYdiLLsptN27ZPzhqGabYdZ339rSzLMfwbxBjX0LUPGJYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7185c4023072602480cffda17942a7ee/e4706/INSTAR_IP_Symcon_Scripts_04.avif 230w", "/en/static/7185c4023072602480cffda17942a7ee/d1af7/INSTAR_IP_Symcon_Scripts_04.avif 460w", "/en/static/7185c4023072602480cffda17942a7ee/7f308/INSTAR_IP_Symcon_Scripts_04.avif 920w", "/en/static/7185c4023072602480cffda17942a7ee/c91b9/INSTAR_IP_Symcon_Scripts_04.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7185c4023072602480cffda17942a7ee/a0b58/INSTAR_IP_Symcon_Scripts_04.webp 230w", "/en/static/7185c4023072602480cffda17942a7ee/bc10c/INSTAR_IP_Symcon_Scripts_04.webp 460w", "/en/static/7185c4023072602480cffda17942a7ee/966d8/INSTAR_IP_Symcon_Scripts_04.webp 920w", "/en/static/7185c4023072602480cffda17942a7ee/ef2bd/INSTAR_IP_Symcon_Scripts_04.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7185c4023072602480cffda17942a7ee/81c8e/INSTAR_IP_Symcon_Scripts_04.png 230w", "/en/static/7185c4023072602480cffda17942a7ee/08a84/INSTAR_IP_Symcon_Scripts_04.png 460w", "/en/static/7185c4023072602480cffda17942a7ee/c0255/INSTAR_IP_Symcon_Scripts_04.png 920w", "/en/static/7185c4023072602480cffda17942a7ee/5caea/INSTAR_IP_Symcon_Scripts_04.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7185c4023072602480cffda17942a7ee/c0255/INSTAR_IP_Symcon_Scripts_04.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set the day and time pattern according to your needs and assign your camera instance as target for the action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/75609/INSTAR_IP_Symcon_Scripts_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrklEQVQoz3WQTU8aURSG57d0q121sQuTNjExNTFNDUKFYcY7I4uZu+nSPV03rK1YE0LSEYeKEsGWFMKHi24MicaEfrmyMpV7BwcGhGGup001KjE+eTdn8eR9c7iXHq8vEJycmo6urJ42beOU/mmYrbYNd3BdFwC+5PMzntmgiDy+Oe7FjOfhk2cPRh4tvF7M7lY/5iobucqH7eLaTkXLlrVM6Tpbha/b5b3FN29HH4+PjD2deD7NCaIoh0KzXl905X3TsluWRY4Pat9/1E+Mtt3p9Hqd86u0O10A+PQ553s1Jy+ExPl5TkIIYywIwvLyu6OjX5SQw5/71epecylq1b7RlmUSYlJqUkoajfNuN5vJiKKIMZZlmUMIKYri9/tjsRhjF47T/03oSb3eN002GLi3cBwHAAqFAs/ziqJIknQjx+NxAOg7TuvsjFJqWpbL2O2Hsf9nsVgckjHGPM9fyf0eIcQwDEKIbduMsctaxth1syAIN7MxxoFAQNM0ABi4DO7hsrlUKg3JqqoihMLhcGpzM5FY15PJVCq1MYyuJ3VdT6fTkUhEkiRVVf/JfwFqnH+uhyKuZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/e4706/INSTAR_IP_Symcon_Scripts_05.avif 230w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/d1af7/INSTAR_IP_Symcon_Scripts_05.avif 460w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/7f308/INSTAR_IP_Symcon_Scripts_05.avif 920w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/602a0/INSTAR_IP_Symcon_Scripts_05.avif 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/a0b58/INSTAR_IP_Symcon_Scripts_05.webp 230w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/bc10c/INSTAR_IP_Symcon_Scripts_05.webp 460w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/966d8/INSTAR_IP_Symcon_Scripts_05.webp 920w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/fd213/INSTAR_IP_Symcon_Scripts_05.webp 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/81c8e/INSTAR_IP_Symcon_Scripts_05.png 230w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/08a84/INSTAR_IP_Symcon_Scripts_05.png 460w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/c0255/INSTAR_IP_Symcon_Scripts_05.png 920w", "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/75609/INSTAR_IP_Symcon_Scripts_05.png 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d18aaccf8cd9ba57e5fc45f774f3b569/c0255/INSTAR_IP_Symcon_Scripts_05.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the camera you want to control from the object tree.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc64eec651b6b9e771a00c67cb5fed42/91f10/INSTAR_IP_Symcon_Scripts_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQY012OzWqDQBRG85gd/EF3jg+UPoDrqZBuRAouSiHVUVMI6SabhhR0Y5FpB+nMvbdNgoXmrL/D+RZBEHDOGWNCCAAwxuAMEaKxBEhnrLVElOe567qc8zAMF39ymqbfxmit5yl8qK9ZPAEAF9lxnGv5TghCGIZhmqau694Oh/fjUecPpuuBCK01xhBRlmX/ynEcM3azWt0jkdYaEcdx7Pv+UylqWlLq1EW83C6K4rrsed5yefv41K7Xz03TVFVVlqWs65f9frPdbtpW1rWUcrd7TZLE9/0oin7lH6Gi339fu7ZAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc64eec651b6b9e771a00c67cb5fed42/e4706/INSTAR_IP_Symcon_Scripts_06.avif 230w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/d1af7/INSTAR_IP_Symcon_Scripts_06.avif 460w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/7f308/INSTAR_IP_Symcon_Scripts_06.avif 920w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/6378b/INSTAR_IP_Symcon_Scripts_06.avif 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc64eec651b6b9e771a00c67cb5fed42/a0b58/INSTAR_IP_Symcon_Scripts_06.webp 230w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/bc10c/INSTAR_IP_Symcon_Scripts_06.webp 460w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/966d8/INSTAR_IP_Symcon_Scripts_06.webp 920w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/9012d/INSTAR_IP_Symcon_Scripts_06.webp 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc64eec651b6b9e771a00c67cb5fed42/81c8e/INSTAR_IP_Symcon_Scripts_06.png 230w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/08a84/INSTAR_IP_Symcon_Scripts_06.png 460w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/c0255/INSTAR_IP_Symcon_Scripts_06.png 920w", "/en/static/fc64eec651b6b9e771a00c67cb5fed42/91f10/INSTAR_IP_Symcon_Scripts_06.png 992w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc64eec651b6b9e771a00c67cb5fed42/c0255/INSTAR_IP_Symcon_Scripts_06.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And set the `}<strong parentName="p">{`Action type`}</strong>{` to `}<strong parentName="p">{`Switch instance`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/700c133750017540314fa163a9e20c74/4cdf7/INSTAR_IP_Symcon_Scripts_07a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACyUlEQVQ4y32SXU/TUBjH93G89MagMUQMKAECiARlr13bjRa85cpxM8YH4At4g4FgAgZcO4xGEmMGGEFGgARZJiDyspdua0/bdT0955iuDHAS/3kuTp48v/O8uprbOr1+qrOn7+2bWXKwnc0Xf5/nT7KFnFSCEJp1YYwRQoSQnZ3dgedub4Dq6Ol3PekfaO3ouXO/ZWZ6huSOC5JUlmUAVAUArS5d1xFClmURQja+b7Z1dD/q7O3qferq7O6719J+63bT1PQsIUQBihPUIIyx40+ufm1qbr374PHD9i4XQ9MvRkb8Pl88HifErg3X5TCXb6fsra0tmqaHeT4cCrmCwSDHcYODg6IoWhAahuGkQgjLQHOAuucCpigqHA4zDGPDPM+73W4xkTCq1WKxaNWCEMZA0xHCDfDa2hpFURzHXYO9HmFhAZ2eSftp9fDIOs+iXB7ncqhYbICTyWQgELiA7QaGhz1+vzg3B38dS4dHhcxPI5vDpTLMS9bJaQO8srJCURTP8yzL1mGPJyGKWNNBNmuWy1hViaoTTSPXJv9fOJFAGCuqCmtBumHIqgohbIBXV1edTv+BEdJ13dnQ2enp3t6epmmXC3P2nEqlaJrmOO4K9nq9giDYR6Ioul7BhFRNU1VVJxvGtlnW1Z7/gj0ez/ulpZWtH9Px5anFT6/mPx4cn0HT+La9PyN8nlpcfv1u+dX8h8SXzfX1DYZhGmG7bELyUqkkK1KprKoqAEABqlSSi2XbCsWSYcJU6qbMoigSQgyjAk0TmqZRqSgAOG/HqrXju7FnjyAkchIEADgTNgxDluXL8zShVTVt/+7uLsMwwzwfCoVcl9+Mjo5Go+PRaDQWi42NjaXTaUKIKIqRSCQWi43XNDEx8TISCYfDVJCmadqGh2oKhUJMTSzL+nw+QRAymczk5KTP52NZlqmLZVluaKj/mdsfoP4Aof8R+ul/9ToAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/700c133750017540314fa163a9e20c74/e4706/INSTAR_IP_Symcon_Scripts_07a.avif 230w", "/en/static/700c133750017540314fa163a9e20c74/d1af7/INSTAR_IP_Symcon_Scripts_07a.avif 460w", "/en/static/700c133750017540314fa163a9e20c74/7f308/INSTAR_IP_Symcon_Scripts_07a.avif 920w", "/en/static/700c133750017540314fa163a9e20c74/5075a/INSTAR_IP_Symcon_Scripts_07a.avif 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/700c133750017540314fa163a9e20c74/a0b58/INSTAR_IP_Symcon_Scripts_07a.webp 230w", "/en/static/700c133750017540314fa163a9e20c74/bc10c/INSTAR_IP_Symcon_Scripts_07a.webp 460w", "/en/static/700c133750017540314fa163a9e20c74/966d8/INSTAR_IP_Symcon_Scripts_07a.webp 920w", "/en/static/700c133750017540314fa163a9e20c74/157c9/INSTAR_IP_Symcon_Scripts_07a.webp 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/700c133750017540314fa163a9e20c74/81c8e/INSTAR_IP_Symcon_Scripts_07a.png 230w", "/en/static/700c133750017540314fa163a9e20c74/08a84/INSTAR_IP_Symcon_Scripts_07a.png 460w", "/en/static/700c133750017540314fa163a9e20c74/c0255/INSTAR_IP_Symcon_Scripts_07a.png 920w", "/en/static/700c133750017540314fa163a9e20c74/4cdf7/INSTAR_IP_Symcon_Scripts_07a.png 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/700c133750017540314fa163a9e20c74/c0255/INSTAR_IP_Symcon_Scripts_07a.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can pick the variable you want to switch from the list. In this example we have chosen to move our camera to the saved position 1.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/4cdf7/INSTAR_IP_Symcon_Scripts_07b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACAElEQVQoz42SS28SYRSG5++4xYVWFxibWkmtUOslMBcGTObCP3FhorYL1sywNpFLaBqTqaybuCBNJFwU3JQBZoJzn/m+o/BVipFa39VZnOe87zk51M27my9oNrGbPDg4nJ1++tLutNrds3av3fumj8fn+nik6yN9bJjmZDr1g6DeOHq8t59huPiDHWrv6fPNRDK2EX/z7hBm565tW7ZjO3N5nue5rue6vu8DQBRFAHB0/PHeVmJrJ7Wb2qdST57duf/wRuz2q9dvAcCybbxOCKEgCADg/YdqbCN+K769/ShJ8TxfKMgsw6iqGgaBaZpwhRBCAHByomWzXEGWX+bzFMdlRVFMp9OKomCMHcfBGJPuZbEKa5rGMIwgCLlcjspmL+BSqQQAhmGQeHgOr3Gu1+s0TQuCwPP8HJYkKZPJEBghtNxzbexqtcowjCiKF86yLNM0raoqxpjYksyOF6yOIHCtVmNZVpKkfD5/CZfLZd/3DcP43Yon5o9oAVwPE+fJZGJZ1t+Zr4ejKJpOp2EYEpjs/78wQmg2m/kL/eNgVzkjwzAsy3ZdFwA8zyMvuQo3Go01sKIoCEUEA4AwDDudDnk4kp8M0jSN47g1zovvvzzvcvlV52az+QcsSRLLssVi8fRzt9U66/d73xcaDAbD4ZDU3f7XXq8/GumVSoUgv+CfImTZaWUzHwsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/e4706/INSTAR_IP_Symcon_Scripts_07b.avif 230w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/d1af7/INSTAR_IP_Symcon_Scripts_07b.avif 460w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/7f308/INSTAR_IP_Symcon_Scripts_07b.avif 920w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/5075a/INSTAR_IP_Symcon_Scripts_07b.avif 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/a0b58/INSTAR_IP_Symcon_Scripts_07b.webp 230w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/bc10c/INSTAR_IP_Symcon_Scripts_07b.webp 460w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/966d8/INSTAR_IP_Symcon_Scripts_07b.webp 920w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/157c9/INSTAR_IP_Symcon_Scripts_07b.webp 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/81c8e/INSTAR_IP_Symcon_Scripts_07b.png 230w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/08a84/INSTAR_IP_Symcon_Scripts_07b.png 460w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/c0255/INSTAR_IP_Symcon_Scripts_07b.png 920w", "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/4cdf7/INSTAR_IP_Symcon_Scripts_07b.png 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/80c3d95b18b17ca8cb7ddf82fd9a7e90/c0255/INSTAR_IP_Symcon_Scripts_07b.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Alternatively, you can also go to the `}<strong parentName="p">{`Advanced`}</strong>{` list item and pick e.g. to activate / deactivate the alarm area 1.`}</p>
    <p>{`Whatever you choose here will now be triggered every day at the set time. Helping you to automate your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9053d33519bb7f6d40347f3c71cc2a96/5caea/INSTAR_IP_Symcon_Scripts_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABUElEQVQoz42Py07CUBBA+T0Tf0AoXemK0EbdyQ4tEf0Af8UFawUKlLrSsDJB2tvbBy22c1/tNbQgIW48i0lmMmceNUVRbjqdmW2PJ9PRcDgejczJHtOczO2pNZ8dCqZlWYPBoNVqNZvNWr1evzN6EjKZJpQLkedyR1FIWfhOHmFWVJksyogQ0nV9K59dnD+dnIJ2nSbhcoVwEHHOKaWcUSFl+mGH729utIEsA4Asyyq53W6Xm1X18fIKXobeOg4DP45jKCGECM43QMI4SdbrIAgwxlEUFUWBENI0TVXVWqPRuO33mZQUgDHGOWd7CCEEgNED24lCOI6j6/pONu4f2OoTXp/9b+r5UV4+JqUUQlQn/AIAf+Rej2Sp97VEHkbIC4LA933G2P9kw+BcAKFVa/UzL6HHEELyPHdddycritLtdjHG3jGo5G8xDMPFYlHJP54Dv1d0C2d6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9053d33519bb7f6d40347f3c71cc2a96/e4706/INSTAR_IP_Symcon_Scripts_08.avif 230w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/d1af7/INSTAR_IP_Symcon_Scripts_08.avif 460w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/7f308/INSTAR_IP_Symcon_Scripts_08.avif 920w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/c91b9/INSTAR_IP_Symcon_Scripts_08.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9053d33519bb7f6d40347f3c71cc2a96/a0b58/INSTAR_IP_Symcon_Scripts_08.webp 230w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/bc10c/INSTAR_IP_Symcon_Scripts_08.webp 460w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/966d8/INSTAR_IP_Symcon_Scripts_08.webp 920w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/ef2bd/INSTAR_IP_Symcon_Scripts_08.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9053d33519bb7f6d40347f3c71cc2a96/81c8e/INSTAR_IP_Symcon_Scripts_08.png 230w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/08a84/INSTAR_IP_Symcon_Scripts_08.png 460w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/c0255/INSTAR_IP_Symcon_Scripts_08.png 920w", "/en/static/9053d33519bb7f6d40347f3c71cc2a96/5caea/INSTAR_IP_Symcon_Scripts_08.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9053d33519bb7f6d40347f3c71cc2a96/c0255/INSTAR_IP_Symcon_Scripts_08.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you already have a different device added to the IP Symcon Software you can also set up this device to trigger actions on your camera. Back to the `}<strong parentName="p">{`Object Tree`}</strong>{` click on add, choose `}<strong parentName="p">{`Event`}</strong>{` and this time click on `}<strong parentName="p">{`Trigger`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3290f4fbe185cc5e37d3125738debdf1/7a3d6/INSTAR_IP_Symcon_Scripts_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB90lEQVQoz5XSX2/SUBgGcD6QXrkbxeh0MdtcGOJMgFKgsItStn0iowZxMVkyaGfIold8A7NM3WawlWWGCF2BAqfd+fcaOLCMbSbbk3N5fnmf8+YEgk8X5JSyGApvFj+A87dm1n/8NI9qVs08PrUdx2k77XZ/MBgg5PZ6ALD7+Us4spJWsk/mQ4EXL189erZ0d+bB20IRAHyMB77vYXxGiU+p5w/DOQcASikAfKrs3n88NzsfWgqvBJYjQ3xnJvj6zTva7njNFnfd8el0gRAA4KMQMsTFzY/3grMP5xafL0cC2UxmY30tlUrubG2Rb9+7h0fY+k3rx+zkT/frHrFPYaQBgDEGADuGoaTSG+trq6vZQDqdVlU1GouVtreBEDpA3PPGByFOKZ9E1NZ1XZIkVVUVRZngaLRcLnMAPOo5HAZAxFsnEZOnsKIomqbFYjHDMBhjCCFxlRDacrqM8as4kUjkcrlMJjPE+Xw+Ho8LbNu27/tiSTAdgQ3DkGVZ07RrcKPRsG3bdV3GGBs99aYYAPr9PiFkAm+Je70exni8s9viTqdDLnyMm2Jd10Xt88kXwzk/3/YlrEmSVCqVMD5rNpsIIXH1UkSjSqXy39pCwnURP6xarU5hTdNkWS4U3u/t1w8ODi3LOrmSX6Zlmlaz1dJ1PZlMCvwPDGrZgjYlVfwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3290f4fbe185cc5e37d3125738debdf1/e4706/INSTAR_IP_Symcon_Scripts_09.avif 230w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/d1af7/INSTAR_IP_Symcon_Scripts_09.avif 460w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/7f308/INSTAR_IP_Symcon_Scripts_09.avif 920w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/a96ba/INSTAR_IP_Symcon_Scripts_09.avif 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3290f4fbe185cc5e37d3125738debdf1/a0b58/INSTAR_IP_Symcon_Scripts_09.webp 230w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/bc10c/INSTAR_IP_Symcon_Scripts_09.webp 460w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/966d8/INSTAR_IP_Symcon_Scripts_09.webp 920w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/a66df/INSTAR_IP_Symcon_Scripts_09.webp 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3290f4fbe185cc5e37d3125738debdf1/81c8e/INSTAR_IP_Symcon_Scripts_09.png 230w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/08a84/INSTAR_IP_Symcon_Scripts_09.png 460w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/c0255/INSTAR_IP_Symcon_Scripts_09.png 920w", "/en/static/3290f4fbe185cc5e37d3125738debdf1/7a3d6/INSTAR_IP_Symcon_Scripts_09.png 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3290f4fbe185cc5e37d3125738debdf1/c0255/INSTAR_IP_Symcon_Scripts_09.png",
              "alt": "IP Symcon Event Scripts & INSTAR Cameras",
              "title": "IP Symcon Event Scripts & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you set up your thirdparty device to switch a `}<strong parentName="p">{`System Variable`}</strong>{` you can now select it here to trigger a function on your camera.`}</p>
    <p>{`You can also find more information in the `}<a parentName="p" {...{
        "href": "https://github.com/Wolbolar/IPSymconInstar/blob/master/docs/de/README.md"
      }}>{`README`}</a>{` of the INSTAR Addons.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      